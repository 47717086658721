import {
  Api,
  ChatExamineeModel,
  ChatMessageModel,
  ChatModel,
  ChatRole,
  ChatState,
  Exam,
  ExamineeModel,
  ExamineeRowModel,
  ExamModel,
  ExamWithExamineesModel,
  FullChatModel,
  LastActivityModel,
  NetworkTestResult,
  ReviewComment,
  ReviewerModel,
  ReviewState,
  ReviewStateModel,
  SessionModel,
  WhoAmIModel,
} from "./Api";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { DateTime } from "luxon";
import { JsxFragment } from "typescript";

const baseUrl = process.env.REACT_APP_API_URI || window.location.protocol + "//" + window.location.host;

function getApiClient() {
  var client = new Api({
    baseApiParams: {
      credentials: "include",
      headers: {},
      redirect: "error",
      referrerPolicy: "no-referrer",
    },
  });

  console.log(baseUrl);

  client.baseUrl = baseUrl;

  return client;
}

export function getStreamingHubConnection() {
  var hubConnection = new HubConnectionBuilder()
    .withAutomaticReconnect()
    .withUrl(baseUrl + "/hub/StreamingHub")
    .build();

  return hubConnection;
}

export function getChatHubConnection() {
  var hubConnection = new HubConnectionBuilder()
    .withAutomaticReconnect()
    .withUrl(baseUrl + "/hub/ChatHub")
    .build();

  return hubConnection;
}

export class ApiClient {
  static api = getApiClient();

  public static currentUser: WhoAmIViewModel;

  static async whoAmI() {
    try {
      var data = await this.api.whoami.whoamiList();

      this.currentUser = new WhoAmIViewModel(data);
      return this.currentUser;
    } catch(e) {
      if (window.location.href.indexOf("login?redirectUrl") !== -1) {
        alert("Login failed");

        return null;
      }

      alert("Login failed" +e);
      window.location.href = this.api.baseUrl + "/login?redirectUrl=" + encodeURIComponent(window.location.href);

      return null;
    }
  }

  static async setChatIntroductionText(text: string) {
    await this.api.chat.introductiontextUpdate({ introductionText: text });
  }

  static async getBookmarks(sessionId: number) {
    return await this.api.session.bookmarkDetail(sessionId);
  }

  static async getExamineeLogs(examineeId: number) {
    return await this.api.log.examineeDetail(examineeId);
  }

  static async createBookmark(sessionId: number, comment: string, positionInSeconds: number) {
    await this.api.session.bookmarkCreate(sessionId, {
      comment: comment,
      positionInSeconds: positionInSeconds,
    });
  }

  static async createBookmarkFromStream(sessionId: number, comment: string, timestamp: string) {
    await this.api.session.bookmarkFromStreamCreate(sessionId, {
      comment: comment,
      timestamp: timestamp,
    });
  }

  static async getTime() {
    return await this.api.time.timeList();
  }

  static async examineeSetReviewUpdate(examineeId: number, reviewState: ReviewState) {
    await this.api.examinee.setReviewUpdate(examineeId, {
      reviewState,
    });
  }

  static async examineeAddCommentCreate(examineeId: number, comment: string) {
    await this.api.examinee.addCommentCreate(examineeId, {
      comment,
    });
  }

  static async getChatIntroductionText() {
    return await this.api.chat.introductiontextList();
  }

  static async getAllReviewers() {
    return (await this.api.reviewer.reviewerList()).map((r) => new ReviewerViewModel(r));
  }

  static async getFullChat(chatId: number) {
    return new FullChatViewModel(await this.api.chat.chatDetail(chatId));
  }

  static async getFullChatFromRecordingUser(recordingUserId: number) {
    let result = await this.api.chat.recordinguserDetail(recordingUserId);

    if (result === null) {
      return null;
    }

    return new FullChatViewModel(result);
  }

  static async getAllChats() {
    return (await this.api.chat.chatList()).map((c) => new ChatViewModel(c));
  }

  static async examineeAllowstreaming(examId: number, examineeIds: number[], allowStreaming: boolean) {
    await this.api.exam.examineeAllowstreamingUpdate(examId, { allowStreaming: allowStreaming, examineeIds: examineeIds });
  }

  static async sendChatMessage(chatId: number, text: string) {
    await this.api.chat.messageCreate(chatId, { text: text });
  }

  static async getNetworkTests(recordingUserId: number) {
    return await this.api.recordinguser.networktestDetail(recordingUserId);
  }

  static async getExams(searchText?: string) {
    return (await this.api.exam.examList({ searchText: searchText })).map((exam) => new ExamStatsViewModel(exam));
  }
  static async getFaceCompares() {
    return (await this.api.session.facecompareTodayList()).map((x) => new ExamineeFaceCompareViewModel(
      x.examineeId!,
      x.sessionId!,
      x.examName!,
      x.username!,
      x.name!,
      x.automaticFaceCompareResult ?? null,
      x.manualFaceCompareResult ?? null,
      x.recordingId!,
      DateTime.fromISO(x.recordingStart!)
      ));
  }

  static async getFaceCompareSnapshots(sessionId: number) {
    return (await this.api.session.facecompareSnapshotsDetail(sessionId));
  }
  static async scoreSnapshots(sessionId: number) {
    await this.api.session.facecompareScoresnapshotsCreate(sessionId);
  }
  static async sessionSetManualResult(sessionId: number, result: boolean) {
    await this.api.session.facecompareManualresultCreate(sessionId, result);
  }

  static async getExam(id: number) {
    return new ExamWithExamineesViewModel(await this.api.exam.examDetail(id));
  }
  static async getExaminee(id: number) {
    return new ExamineeViewModel(await this.api.examinee.examineeDetail(id));
  }
  static async getSession(sessionId: number) {
    return await this.api.session.sessionDetail(sessionId);
  }

  static async setChatFields(chatId: number, chatState: ChatStateViewModel, chatRole: ChatRoleViewModel, assignedToReviewerId: number | null) {
    await this.api.chat.fieldCreate(chatId, {
      assignedToReviewerId: assignedToReviewerId,
      chatRole: chatRole.getInnerValue(),
      chatState: chatState.getInnerValue(),
    });
  }
}

export class FullChatViewModel {
  constructor(model: FullChatModel) {
    if (!model.chat) throw new Error("chat not set");
    if (!model.messages) throw new Error("messages not set");

    this.chat = new ChatViewModel(model.chat);
    this.messages = model.messages.map((m) => new ChatMessageViewModel(m));
  }

  chat: ChatViewModel;
  messages: ChatMessageViewModel[];
}

export class ExamWithExamineesViewModel {
  exam: ExamViewModel;
  examinees: ExamineeRowViewModel[];

  constructor(model: ExamWithExamineesModel) {
    if (!model.exam) {
      throw "model.exam not set";
    }

    this.exam = new ExamViewModel(model.exam);
    this.examinees = (model.examinees || []).map((e) => new ExamineeRowViewModel(e));
  }
}

export class ExamineeRowViewModel {
  examineeId: number;
  username: string;
  name: string;
  end: string;
  lastActivity: LastActivityModel;
  bookmarkCount: number;
  allowStreaming: boolean;
  reviewState: ReviewStateModel;
  streamReviewer: ReviewerViewModel | null;
  recordingUserId: number;
  networkTestResult: NetworkTestResultViewModel;

  constructor(model: ExamineeRowModel) {
    this.examineeId = model.examineeId || -1;
    this.username = model.username || "";
    this.name = model.name || "";
    this.end = model.end || "";
    this.lastActivity = model.lastActivity!;
    this.bookmarkCount = model.bookmarkCount!;
    this.allowStreaming = model.allowStreaming!;
    this.streamReviewer = model.streamReviewer ? new ReviewerViewModel(model.streamReviewer) : null;
    this.recordingUserId = model.recordingUserId!;
    this.networkTestResult = new NetworkTestResultViewModel(model.networkTestResult!);

    if (!model.reviewState) {
      throw "model.reviewstate not set";
    }

    this.reviewState = model.reviewState;
  }
}

export class ReviewerViewModel {
  id: number;
  name: string;

  constructor(model: ReviewerModel) {
    this.id = model.id || -1;
    this.name = model.name || "";
  }
}

export class ExamineeViewModel {
  id: number;
  recordingUserId: number;
  username: string;
  name: string;
  examId: number;
  examName: string;
  examType: string | null;
  examEducation: string | null;
  reviewState: ReviewStateModel;
  sessions: SessionModel[];
  networkTestResult: NetworkTestResultViewModel;

  constructor(model: ExamineeModel) {
    this.id = model.id || -1;
    this.recordingUserId = model.recordingUserId || -1;
    this.username = model.username || "";
    this.name = model.name || "";
    this.examId = model.examId || -1;
    this.examName = model.examName || "";
    this.examType = model.examType || "";
    this.examEducation = model.examEducation || "";

    if (!model.reviewState) {
      throw "model.reviewstate not set";
    }

    this.reviewState = model.reviewState;
    this.sessions = model.sessions || [];
    this.networkTestResult = new NetworkTestResultViewModel(model.networkTestResult!);
  }
}

export class NetworkTestResultViewModel {
  public state: "success" | "warning" | "error" | "no-results";

  constructor(value: NetworkTestResult) {
    switch (value) {
      case NetworkTestResult.Value0:
        this.state = "success";
        break;
      case NetworkTestResult.Value1:
        this.state = "warning";
        break;
      case NetworkTestResult.Value2:
        this.state = "error";
        break;
      case NetworkTestResult.Value3:
        this.state = "no-results";
        break;
    }
  }
}

export class ChatViewModel {
  chatRole: ChatRoleViewModel;
  assignedToReviewerId: number | null;
  assignedToReviewerName: string | null;
  id: number;
  name: string;
  recordingUserId: number;
  username: string;
  chatState: ChatStateViewModel;
  lastActivity: DateTime | null;
  userExams: ChatUserExamModel[];
  allowStreaming: boolean;
  networkTestResult: NetworkTestResultViewModel;

  constructor(model: ChatModel) {
    if (!model.id) throw new Error("chat.id not set");
    if (!model.recordingUserId) throw new Error("chat.userId not set");
    if (model.chatRole === null || model.chatRole === undefined) throw new Error("chat.chatRole not set");
    if (model.chatState === null || model.chatState === undefined) throw new Error("chat.chatState not set");

    this.assignedToReviewerId = model.assignedToReviewerId || null;
    this.assignedToReviewerName = model.assignedToReviewerName || null;
    this.id = model.id;
    this.networkTestResult = new NetworkTestResultViewModel(model.networkTestResult!);

    this.name = model.name || "No name set";
    this.recordingUserId = model.recordingUserId;
    this.username = model.username || "No username set";
    this.allowStreaming = model.allowStreaming || false;

    this.chatRole = new ChatRoleViewModel(model.chatRole);
    this.chatState = new ChatStateViewModel(model.chatState);
    this.lastActivity = model.lastActivity ? DateTime.fromISO(model.lastActivity) : null;

    this.userExams = model.userExams ? model.userExams.map((ue) => new ChatUserExamModel(ue)) : [];
  }
}

export class ChatUserExamModel {
  examId: number;
  examineeId: number;
  name: string;

  constructor(model: ChatExamineeModel) {
    if (!model.examId) throw new Error("examId not set");
    if (!model.examineeId) throw new Error("examineeId not set");
    if (!model.examStart) throw new Error("examStart not set");

    this.examId = model.examId;
    this.examineeId = model.examineeId;
    this.name = DateTime.fromISO(model.examStart).toFormat("d/M kl. HH:mm");
  }
}

export class ChatRoleViewModel {
  name: string;
  backgroundColor: string;
  color: string = "#F3F3F3";
  show = true;

  constructor(private model: ChatRole) {
    switch (model) {
      case 0:
        this.name = "Ikke tildelt";
        this.show = false;
        this.backgroundColor = "#F00";
        break;

      case 1:
        this.name = "Supporter";
        this.backgroundColor = "#FFA500";
        break;

      case 2:
        this.name = "Sagsbehandler";
        this.backgroundColor = "#008080";
        break;
      default:
        throw new Error("Invalid chatRole: " + JSON.stringify(model));
    }
  }

  getIntValue = (): number => {
    return this.model;
  };

  getInnerValue = () => this.model;
}

export class ExamStatsViewModel {
  exam: ExamViewModel;
  examineeCount: number;

  constructor(private model: ExamModel) {
    if (!model.exam) throw new Error("exam.exam not set");
    if (!model.examineeCount) throw new Error("exam.examineeCount not set");

    this.exam = new ExamViewModel(model.exam);
    this.examineeCount = model.examineeCount;
  }
}

export class ExamineeFaceCompareViewModel
{
  constructor(
    public examineeId: number, 
    public sessionId: number,
    public examName: string,
    public username: string,
    public name: string,
    public automaticFaceCompareResult: boolean | null,
    public manualFaceCompareResult: boolean | null,
    public recordingId: number,
    public recordingStart: DateTime)
    {

    }
}

export class ExamViewModel {
  id: number;
  name: string;
  start: DateTime;
  education: string | null | undefined;
  type: string | null | undefined;

  constructor(private model: Exam) {
    if (!model.id) throw new Error("exam.id not set");
    if (!model.defaultTitle) throw new Error("exam.defaultTitle not set");
    if (!model.start) throw new Error("exam.start not set");

    this.type = model.type;
    this.education = model.education;

    this.id = model.id;
    this.name = model.defaultTitle;
    this.start = DateTime.fromISO(model.start);
  }
}

export class ChatStateViewModel {
  name: string;
  backgroundColor: string;
  color: string = "#000";
  atStudent: boolean;

  constructor(private model: ChatState) {
    switch (model) {
      case 0:
        this.name = "Afventer KU";
        this.backgroundColor = "#8A181B";
        this.color = "#EEE";
        this.atStudent = false;
        break;

      case 1:
        this.name = "Hos ansøger/ inaktiv";
        this.backgroundColor = "#CCC";
        this.atStudent = true;
        break;

      default:
        throw new Error("Invalid chatState: " + JSON.stringify(model));
    }
  }

  getIntValue = (): number => {
    return this.model;
  };

  getInnerValue = () => this.model;
}

export class ReviewCommentViewModel {
  created: DateTime;
  id: number;
  comment: string;
  reviewerName: string;

  constructor(model: ReviewComment) {
    this.id = model.id || 0;
    this.created = DateTime.fromISO(model.created || "");
    this.comment = model.comment || "";
    this.reviewerName = model.reviewerName || "";
  }
}

export class ChatMessageViewModel {
  sent: DateTime;
  id: number;
  name: string;
  username: string;
  isEmployee: boolean;
  text: string;

  constructor(model: ChatMessageModel) {
    if (!model.id) throw new Error("chat.id not set");
    if (model.isEmployee === undefined) throw new Error("chat.isEmployee not set");
    if (!model.sent) throw new Error("chat.sent not set");
    if (!model.text) throw new Error("chat.text not set");

    this.id = model.id;
    this.name = model.name || "Name not set";
    this.sent = DateTime.fromISO(model.sent);
    this.username = model.username || "Username not set";
    this.isEmployee = model.isEmployee;
    this.text = model.text;
  }
}

export class WhoAmIViewModel {
  name: string;
  reviewerId: number;
  enableLiveStreaming: boolean;
  enableChat: boolean;
  enableIdCard: boolean;

  constructor(model: WhoAmIModel) {
    if (!model.name) throw new Error("name not set");
    if (!model.reviewerId) throw new Error("reviewerId not set");

    this.name = model.name;
    this.reviewerId = model.reviewerId;
    this.enableChat = model.enableChat || false;
    this.enableIdCard = model.enableIdCard || false;
    this.enableLiveStreaming = model.enableLiveStreaming ||false;
  }
}
