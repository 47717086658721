import Badge from "@mui/material/Badge";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import ChatIcon from "@mui/icons-material/Chat";
import CircularProgress from "@mui/material/CircularProgress";
import { flexbox } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import {
  ApiClient,
  ChatViewModel,
  FullChatViewModel,
  ExamStatsViewModel,
  getChatHubConnection,
  getStreamingHubConnection,
  WhoAmIViewModel,
} from "../../ApiHelper";
import { HubConnection } from "@microsoft/signalr";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { Add, Api, BookmarkAdd, Check, Navigation, Padding, RestartAlt, StopCircle } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { userInfo } from "os";
import Skeleton from "@mui/material/Skeleton";
import { ConnectionHolder, IPendingHolder, WebRtcConnectionController } from "./WebRtcConnectionController";
import { LoadingButton } from "@mui/lab";
import { Tooltip, ToggleButtonGroup, ToggleButton, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from "@mui/material";
import ChatStatusFields from "../chat/ChatStatusFields";

function StreamIndex() {
  const [ready, setReady] = useState(false);

  if (ready) {
    return <StreamIndexView></StreamIndexView>;
  }

  // diverse video funktioner virker først efter user interaction i browseren...
  return (
    <>
      <Button variant="outlined" onClick={() => setReady(true)}>
        Gå til streams
      </Button>
    </>
  );
}

const webRtcConnectionController = new WebRtcConnectionController();

class StreamUser {
  constructor(public username: string, public name: string, public sessionId: number, public recordingUserId: number) {}

  public stopStreamingBadConnection = false;
  public connections: ConnectionHolder[] = [];
}

function StreamIndexView() {
  const [streamUsers, setStreamUsers] = useState<StreamUser[]>([]);
  const [pending, setPending] = useState<IPendingHolder[]>([]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [i, setI] = useState(0);

  useEffect(() => {
    let dispose: () => void;

    let interval = setInterval(() => setI((x) => x + 1), 1000);

    webRtcConnectionController.start();

    webRtcConnectionController.stopStreamingBadConnection = (sessionId: number) => {
      for (let streamUser of streamUsers.filter((s) => s.sessionId === sessionId)) {
        streamUser.stopStreamingBadConnection = true;
      }

      setI(i + 1);
    };

    webRtcConnectionController.connectionsUpdated = (c) => {
      const newStreamUserArray = streamUsers.map((x) => x);
      for (let connection of c) {
        if (!newStreamUserArray.some((s) => s.username === connection.username)) {
          newStreamUserArray.push(new StreamUser(connection.username, connection.name, connection.sessionId, connection.recordingUserId));
        }

        for (let streamUser of newStreamUserArray.filter((s) => s.username === connection.username)) {
          if (!streamUser.connections.some((c) => c.connectionHolderId === connection.connectionHolderId)) {
            streamUser.connections.push(connection);
          }
        }
      }

      setStreamUsers(newStreamUserArray);
      setI(i + 1);
    };

    webRtcConnectionController.pendingUpdated = (c) => {
      setPending(c);
    };

    dispose = () => {
      webRtcConnectionController.stop();
      clearInterval(interval);
    };

    return () => {
      if (dispose) {
        dispose();
      }
    };
  }, []);

  return (
    <>
      <Box height="calc(100vh - 80px)" display="flex" flexDirection="column">
        <Box flex={1} overflow="auto">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {streamUsers.map((streamUser) => (
              <StreamUserView
                key={streamUser.username}
                streamUser={streamUser}
                i={i}
                onRemove={() => setStreamUsers((s) => s.filter((x) => x.username !== streamUser.username))}
              />
            ))}
          </div>
        </Box>
        <Box>
          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            {pending.length > 0 && (
              <>
                <Fab color="primary" aria-label="add" variant="extended" onClick={handleClick}>
                  <Add sx={{ mr: 1 }} />
                  Der er {pending.length} nye stream(s)
                </Fab>

                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  {pending.map((u) => {
                    return (
                      <Typography sx={{ p: 2 }} key={u.connectionId}>
                        User: {u.username}{" "}
                        <Button
                          onClick={() => {
                            webRtcConnectionController.tryConnectToPending?.(u);
                            handleClose();
                          }}
                        >
                          Connect
                        </Button>
                      </Typography>
                    );
                  })}
                </Popover>
              </>
            )}

            {!pending.length && (
              <Fab variant="extended" disabled>
                <Check sx={{ mr: 1 }} />
                Alle streams bliver overvåget
              </Fab>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

function StreamUserView(props: { streamUser: StreamUser; i: number; onRemove: () => void }) {
  return (
    <>
      <div
        style={{
          width: "33%",
          maxWidth: "30vw",
          position: "relative",
          backgroundColor: "white",
        }}
      >
        <StreamUserControls streamUser={props.streamUser} />

        {props.streamUser.stopStreamingBadConnection && (
          <>
            <div style={{ padding: "20px" }}>
              <div style={{ marginBottom: "20px" }}>Dårlig forbindelse detekteret. Stream afbrudt.</div>

              <Button onClick={props.onRemove}>Fjern</Button>
            </div>
          </>
        )}

        {!props.streamUser.connections.some((c) => c.isVisible() && !props.streamUser.stopStreamingBadConnection) && (
          <>
            <div style={{ padding: "20px" }}>
              <div style={{ marginBottom: "20px" }}>Ingen aktive forbindelser</div>

              <Button onClick={props.onRemove}>Fjern</Button>
            </div>
          </>
        )}

        {props.streamUser.connections.map((c) => (
          <ConnectionView key={c.connectionHolderId} c={c} i={props.i} />
        ))}
      </div>
    </>
  );
}

function ConnectionView(props: { c: ConnectionHolder; i: number }) {
  const c = props.c;

  let activeVideoStreams = c.videostreams.filter((x) => !x.getTracks()[0].muted);

  const [activeStream, setActiveStream] = useState<MediaStream | null>(activeVideoStreams[0] || null);

  useEffect(() => {
    if (activeStream === null) {
      setActiveStream(activeVideoStreams[0] || null);
    }
  });

  const swap = () => {
    if (!activeVideoStreams.length) {
      return;
    }

    if (activeStream === null) {
      if (activeVideoStreams.length > 1) {
        // webcam er typisk først, men vi vil gerne have webcam
        setActiveStream(activeVideoStreams[1]);
      } else {
        setActiveStream(activeVideoStreams[0]);
      }
      return;
    }

    let nextIndex = 0;

    for (let i = 0; i < activeVideoStreams.length; i++) {
      if (activeVideoStreams[i].id === activeStream.id) {
        nextIndex = i + 1;
        break;
      }
    }

    nextIndex = nextIndex % activeVideoStreams.length;

    setActiveStream(activeVideoStreams[nextIndex]);
  };

  if (!activeVideoStreams.length) {
    return <></>;
  }

  return (
    <div>
      <div>
        <ConnectionControls onStopConnection={() => props.c.disconnectAndUnassign()} onRestartConnection={() => props.c.restartConnection()} onSwap={swap} />
      </div>

      <div style={{ position: "absolute" }}>
        {activeVideoStreams
          .filter((x) => x.id !== activeStream?.id)
          .filter((x) => !x.getTracks()[0].muted)
          .map((s) => (
            <div
              key={s.id}
              style={{
                width: "30%",
                zIndex: 1,
              }}
            >
              <VideoStreamView key={s.id} stream={s} i={props.i} controls={false} />
            </div>
          ))}
      </div>

      <div>{activeStream !== null ? <VideoStreamView key={activeStream.id} stream={activeStream} i={props.i} controls={true} /> : <></>}</div>

      {activeVideoStreams.length === 0 && (
        <>
          <Skeleton variant="rectangular" width={"100%"} height={200} />
        </>
      )}

      {c.audiostreams.map((a) => (
        <AudioStreamView key={a.id} stream={a} />
      ))}
    </div>
  );
}

function StreamUserControls(props: { streamUser: StreamUser }) {
  const href = "/app/recordings/session/" + props.streamUser.sessionId + "?showInitialChat=true";

  const [chat, setChat] = useState<FullChatViewModel | null>(null);

  const refreshChat = async () => {
    var chat = await ApiClient.getFullChatFromRecordingUser(props.streamUser.recordingUserId);

    setChat(chat);
  };

  useEffect(() => {
    (async () => {
      await refreshChat();
    })();

    var hubConnection = getChatHubConnection();

    const methodName = "chat-recordinguser-" + props.streamUser.recordingUserId;

    console.log(methodName);
    hubConnection.on(methodName, () => {
      console.log("Received chat message");

      refreshChat();
    });

    console.log("Hub start");
    hubConnection.start();

    return () => {
      hubConnection.stop();
    };
  }, []);

  return (
    <>
      <div>
        {props.streamUser.name} ({props.streamUser.username})
        <div style={{ float: "right" }}>
          <StreamAddBookmarkButtonAndDialog sessionId={props.streamUser.sessionId} />
          <a
            style={{
              padding: "10px",
            }}
            href={href}
            title="Gå til optagelse/chat"
            target="myNamedWindow"
          >
            {chat && !chat.chat.chatState.atStudent && <ChatIcon sx={{ cursor: "pointer", color: "red" }} fontSize={"small"} />}
            {chat && chat.chat.chatState.atStudent && <ChatIcon sx={{ cursor: "pointer" }} fontSize={"small"} />}
          </a>
        </div>
      </div>
    </>
  );
}

function ConnectionControls(props: { onStopConnection: () => void; onRestartConnection: () => void; onSwap: () => void }) {
  const confirmStopConnection = () => {
    if (window.confirm("Er du sikker på du ønsker at stoppe forbindelse? Dette vil fjerne dig som overvåger for denne optagelse.")) {
      props.onStopConnection?.();
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#FFF9",
          display: "flex",
          width: "100%",
        }}
      >
        <div style={{ flexGrow: 1 }}></div>

        <div>
          <a
            href="#"
            style={{
              padding: "10px",
            }}
            title="Skift billede"
            onClick={() => props.onSwap()}
          >
            <CameraswitchIcon sx={{ cursor: "pointer" }} fontSize={"small"} />
          </a>

          <a
            href="#"
            style={{
              padding: "10px",
            }}
            onClick={() => props.onRestartConnection()}
            title="Genstart forbindelse"
          >
            <RestartAlt sx={{ cursor: "pointer" }} fontSize={"small"} />
          </a>

          <a
            href="#"
            style={{
              padding: "10px",
            }}
            onClick={() => confirmStopConnection()}
            title="Stop forbindelse"
          >
            <StopCircle sx={{ cursor: "pointer" }} fontSize={"small"} />
          </a>
        </div>
      </div>
    </>
  );
}

export function StreamAddBookmarkButtonAndDialog(props: { sessionId: number }) {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [timestamp, setTimestamp] = useState("");

  const [saving, setSaving] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSaving(false);
    setComment("");
  };

  const showDialog = async () => {
    setTimestamp(await ApiClient.getTime());
    setOpen(true);
  };

  const save = () => {
    setSaving(true);

    const saveData = async () => {
      await ApiClient.createBookmarkFromStream(props.sessionId, comment, timestamp);

      handleClose();
    };

    saveData().catch(() => {
      alert("Der skete en fejl");
      setSaving(false);
    });
  };

  return (
    <>
      <Tooltip title="Sæt bogmærke">
        <a
          href="#"
          style={{
            padding: "10px",
          }}
          onClick={showDialog}
        >
          <BookmarkAddIcon sx={{ cursor: "pointer" }} fontSize={"small"} />
        </a>
      </Tooltip>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Sæt bogmærke</DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          <DialogContentText>Tilføj et bogmærke til denne optagelse.</DialogContentText>

          <Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                autoFocus
                id="name"
                multiline
                label="Kommentar"
                fullWidth
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                variant="standard"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuller</Button>
          <LoadingButton onClick={save} loading={saving} variant="outlined">
            Opret bogmærke
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

function AudioStreamView(props: { stream: MediaStream }) {
  const audioElement = useRef<HTMLVideoElement>(null);

  const [loaded, setLoaded] = useState(false);

  const [audioMeter, setAudioMeter] = useState(0);

  useEffect(() => {
    if (audioElement.current) {
      if (!audioElement.current.srcObject) {
        console.log("Add audio stream to srcObject: " + props.stream.id);
        audioElement.current.srcObject = props.stream;

        audioElement.current.play.call(audioElement.current);

        setLoaded(true);
      } else {
        console.log("Audio stream already found on srcObject: " + props.stream.id);
      }
    } else {
      throw "Audio element not created yet";
    }

    let max = 1;

    const audioContext = new AudioContext();
    const analyzer = audioContext.createAnalyser();
    analyzer.smoothingTimeConstant = 0.8;
    analyzer.fftSize = 1024;
    const sourceNode = audioContext.createMediaStreamSource(props.stream);
    sourceNode.connect(analyzer);

    let array = new Uint8Array(analyzer.frequencyBinCount);

    // Analyze the sound
    setInterval(() => {
      analyzer.getByteFrequencyData(array);
      var values = 0;

      var length = array.length;
      for (var i = 0; i < length; i++) {
        values += array[i];
      }

      var average = values / length;

      if (average > max) {
        max = average;
      }

      setAudioMeter(Math.round((100 * average) / max));
    }, 100);
  }, []);

  return (
    <div>
      <audio controls autoPlay={true} ref={audioElement} style={{ display: "none" }}></audio>

      <div
        style={{
          width: "100px",
          height: "10px",
          border: "1px solid black",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            width: audioMeter + "px",
            height: "10px",
            backgroundColor: "red",
          }}
        ></div>
      </div>
    </div>
  );
}

function VideoStreamView(props: { stream: MediaStream; i: number; controls: boolean }) {
  const videoElement = useRef<HTMLVideoElement>(null);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (videoElement.current) {
      if (!videoElement.current.srcObject) {
        console.log("Add stream to srcObject: " + props.stream.id);
        videoElement.current.srcObject = props.stream;

        videoElement.current.play.call(videoElement.current);

        setLoaded(true);
      } else {
        console.log("Stream already found on srcObject: " + props.stream.id);
      }
    } else {
      throw "Video element not created yet";
    }
  }, []);

  props.stream.onremovetrack = () => alert("remove track");

  return (
    <div data-id={props.i}>
      <video style={{ width: "100%" }} autoPlay={true} controls={props.controls} ref={videoElement}></video>
    </div>
  );
}

export default StreamIndex;
