import { useEffect, useState } from "react";
import { RecordingModel, RecordingPartModel, SessionModel } from "../Api";
import { ApiClient } from "../ApiHelper";
import { DateTime } from "luxon";
import Slider from "@mui/material/Slider";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Play from "@mui/icons-material/PlayArrow";
import IconButton from "@mui/material/IconButton/IconButton";
import Pause from "@mui/icons-material/Pause";

export function ScreenshotSlider(props: { session: SessionModel; autoPlay: boolean, playbackZoom: string }) {
    const [recordings, setRecordings] = useState<{ recording: RecordingModel, recordingParts: RecordingPartModel[] }[] | null>(null);

    const [currentPosition, setCurrentPosition] = useState(0);


    const [playbackRate, setPlaybackRate] = useState<number>(0);

    const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

    useEffect(() => {
        (async () => {

            let arr: { recording: RecordingModel, recordingParts: RecordingPartModel[] }[] = [];

            for (let recording of props.session.screenshotRecordings || []) {

                const result = await ApiClient.api.recording.partDetail(recording.id!);

                arr.push({
                    recording: recording,
                    recordingParts: result
                })
            }

            setRecordings(arr);
        })();
    }, []);

    

    useEffect(() => {
        return () => {
            if(intervalId)
            {
                clearInterval(intervalId);
            }
        }
    }, []);

    if (recordings == null) {
        return <div>Loading</div>
    }

    if (recordings.length === 0) {
        return <></>
    }

    recordings[0].recordingParts.reduce((acc2, current) => Math.max(acc2, current.relativeTimeInSeconds!), 0)

    const max = recordings.reduce((acc1, r) => Math.max(acc1, r.recordingParts.reduce((acc2, current) => Math.max(acc2, current.relativeTimeInSeconds!), 0)), 0);

    const onSliderChange = (e: Event, a: number | number[]) => {
        console.log('wy ' + a);
        setCurrentPosition(a as number);
        setRate("0");
    }

    const startTime = DateTime.fromISO(props.session.firstRecordingStarted!);

    const currentTime = startTime.plus({ seconds: currentPosition });

    function setRate(value: string): void {

        const newRate = parseInt(value)
        setPlaybackRate(newRate);

        console.log('setrate ' + newRate);
        if (intervalId) {
            clearInterval(intervalId);
        }

        if (newRate > 0) {
            setIntervalId(setInterval(() => {
                setCurrentPosition(x => {
                    if(max > x)
                    {
                        return x + 1
                    }

                    return x;
                });
                console.log('wat ' + currentPosition);

            }, 1000 / newRate));
        }
    }

    return <>
          <IconButton onClick={() => setRate("8")}>
            <Play />
          </IconButton>
          <IconButton onClick={() => setRate("0")}>
            <Pause />
          </IconButton>
          
        <ToggleButtonGroup value={playbackRate + ""} exclusive onChange={(_,b) => setRate(b)}>
            <ToggleButton value="1" aria-label="left aligned">
                1x
            </ToggleButton>
            <ToggleButton value="2" aria-label="centered">
                2x
            </ToggleButton>
            <ToggleButton value="4" aria-label="right aligned">
                4x
            </ToggleButton>
            <ToggleButton value="8" aria-label="justified">
                8x
            </ToggleButton>
            <ToggleButton value="16" aria-label="justified">
                16x
            </ToggleButton>
            <ToggleButton value="32" aria-label="justified">
                32x
            </ToggleButton>
        </ToggleButtonGroup>
        
        {currentTime.toFormat('HH:mm:ss')}

        <Slider aria-label="Volume" value={currentPosition} max={max} onChange={onSliderChange} />

        <div style={{ display: 'flex' }}>
            {recordings?.map(r => <ScreenshotSliderRecording playbackZoom={props.playbackZoom} key={r.recording.id} recording={r.recording} recordingParts={r.recordingParts} currentPosition={currentPosition} />)}
        </div>
    </>;
}

function ScreenshotSliderRecording(props: { recording: RecordingModel, recordingParts: RecordingPartModel[], playbackZoom: string, currentPosition: number }) {
    useEffect(() => {
        applyCache();
    }, []);

    if (props.recordingParts.length === 0) {
        return <div>No recordings</div>;
    }

    const applyCache = async () => {
        const cacheKey = 'flex.remote.screenshotparts';

        var cache = await caches.open(cacheKey);

        for (let part of props.recordingParts) {
            cache.add(new Request(`/recordingpart/${part.id}`));
        }
    };

    let before = props.recordingParts.filter(rp => rp.relativeTimeInSeconds! <= props.currentPosition);

    let first = before.length ? before[before.length - 1] : props.recordingParts[0];

    return <div style={{ flex: "1 0 calc(25% - 10px)", position: 'relative' }}>
        <img style={{ width: "100%" }} src={`/recordingpart/${first.id}`} />
    </div>;
}