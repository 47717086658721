import { Breadcrumbs, Button, Chip, Drawer, Grid, IconButton, Link, Slider, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Box from "@mui/material/Box/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper/Paper";
import { DateTime } from "luxon";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BookmarkModel, ExamineeModel, RecordingPartModel, SessionModel } from "../Api";
import { ApiClient, ExamineeViewModel, FullChatViewModel, NetworkTestResultViewModel, getChatHubConnection } from "../ApiHelper";
import "./RecordingExaminee.css";
import Play from "@mui/icons-material/PlayArrow";
import Pause from "@mui/icons-material/Pause";
import { ReviewStateEdit } from "./ReviewStateEdit";
import ChatLoad from "./chat/ChatLoad";
import Chat from "./chat/Chat";
import React from "react";
import ChatStatusFields from "./chat/ChatStatusFields";
import { AddBookmarkButtonAndDialog } from "./AddBookmarkButtonAndDialog";
import { BookmarkList } from "./BookmarkList";
import { ExamineeLog } from "./ExamineeLog";
import NetworkTestResultView from "./NetworkTestResultView";
import { getFaceDetectionIcon } from "./FaceCompareIndex";
import { ScreenshotSlider } from "./ScreenshotViewer";
import AppContext from "../AppContext";

export function RecordingSessionView() {
  let { sessionId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const session = await ApiClient.getSession(parseInt(sessionId || "0"));
      navigate("/app/recordings/examinee/" + session.examineeId);
    })();
  }, [sessionId]);

  return <CircularProgress />;
}

export function RecordingExaminee() {
  const [examinee, setExaminee] = useState<null | ExamineeViewModel>(null);

  let { examineeId } = useParams();

  let reload = async () => {
    const exam = await ApiClient.getExaminee(parseInt(examineeId || "0"));
    setExaminee(exam);
  };

  useEffect(() => {
    reload();
  }, [examineeId]);

  if (examinee === null) {
    return <CircularProgress />;
  }

  return <ExamineeDetails examinee={examinee} />;
}

function ExamineeDetails(props: { examinee: ExamineeViewModel }) {
  const [showLog, setShowLog] = useState(false);

  const myContext = useContext(AppContext);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/app/recordings">
          Optagelser
        </Link>

        <Link underline="hover" color="inherit" href={"/app/recordings/" + props.examinee.examId}>
          {props.examinee.examName}
        </Link>

        <Link underline="hover" color="inherit">
          {props.examinee.username}
        </Link>
      </Breadcrumbs>

      <Paper sx={{ mb: 1, p: 2 }}>
        {props.examinee.name} ({props.examinee.username}) - {props.examinee.examName}
        <Chip sx={{ ml: 1 }} color="primary" label={props.examinee.examType} variant="outlined" />
        <Chip sx={{ ml: 1 }} color="success" label={props.examinee.examEducation} variant="outlined" />
        <Box sx={{ ml: 1, display: 'inline' }}><NetworkTestResultView recordingUserId={props.examinee.recordingUserId!} value={props.examinee.networkTestResult} /></Box>

        {myContext?.enableChat &&
          <ExamineeChat recordingUserId={props.examinee.recordingUserId ?? 0} />
        }
      </Paper>

      <Paper sx={{ mb: 1, p: 2 }}>
        <Grid container>
          <Grid item sx={{ flexGrow: 1 }}>
            <ReviewStateEdit examineeId={props.examinee.id || 0} reviewState={props.examinee.reviewState || null} />
          </Grid>
          <Grid>
            <Button variant="contained" onClick={() => setShowLog(true)}>
              Vis log
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <React.Fragment>
        <Drawer
          anchor="right"
          open={showLog}
          onClose={() => setShowLog(false)}
          PaperProps={{
            sx: { width: "50%" },
          }}
        >
          {showLog && <ExamineeLog examineeId={props.examinee.id || 0} />}
        </Drawer>
      </React.Fragment>

      {!props.examinee.sessions?.length && (
        <>
          <Paper sx={{ mb: 1, p: 2 }}>Ingen optagelser</Paper>
        </>
      )}

      {props.examinee.sessions?.map((session, i) => {
        return <SessionDetails key={session.id} session={session} autoPlay={i === 0} />;
      })}
    </>
  );
}

function getUrl(path: string) {
  return ApiClient.api.baseUrl + path;
}


function SessionDetails(props: { session: SessionModel; autoPlay: boolean }) {
  const [sliderState, setSliderState] = useState(0);
  const [maxSliderValue, setMaxSliderValue] = useState(0);
  const [playbackRate, setPlaybackRate] = useState("1");
  const [playbackZoom, setPlaybackZoom] = useState("48%");
  const [showFullImage, setShowFullImage] = useState(false);

  const [bookmarks, setBookmarks] = useState<BookmarkModel[] | null>(null);

  const imageSrc = "data:image/webp;base64," + props.session.screenshot?.bytes;

  const eventGroup = "session-" + props.session.id + "-";

  const reloadBookmarks = async () => {
    setBookmarks(await ApiClient.getBookmarks(props.session.id || 0));
  };

  useEffect(() => {
    reloadBookmarks();
  }, []);

  const onSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
    console.log("change");
    eventBus.dispatch(eventGroup + "setPosition", { currentTime: value });
    setSliderState(value as number);
    eventBus.dispatch(eventGroup + "pause", null);
  };

  const onDurationLoaded = (durationInSeconds: number) => {
    setMaxSliderValue(Math.max(durationInSeconds, maxSliderValue));
  };

  const onTimeChanged = (positionInSeconds: number) => {
    console.log(positionInSeconds);
    setSliderState(positionInSeconds);
  };

  const setRate = (event: React.MouseEvent<HTMLElement>, newRate: string | null) => {
    eventBus.dispatch(eventGroup + "playbackRate-" + newRate, null);
    setPlaybackRate(newRate || "1");
  };

  const setZoom = (event: React.MouseEvent<HTMLElement>, newZoom: string | null) => {
    eventBus.dispatch(eventGroup + "zoom", newZoom);
    setPlaybackZoom(newZoom || "1");
  };

  const pause = () => {
    eventBus.dispatch(eventGroup + "pause", null);
  };

  const hasScreenshots = (props.session.screenshotRecordings?.length || 0) > 0;
  const hasMediaRecordings = (props.session.audioRecordings?.length || 0 + (props.session.webcams?.length || 0) + (props.session.screenCaptures?.length || 0)) > 0;


  return (
    <>
      <Paper elevation={1} sx={{ p: 2, pt: 1, mb: 1 }}>

        <Grid container>
          <Grid item>

            <h3>
              Session startet
              {DateTime.fromISO(props.session.firstRecordingStarted || "").toFormat(" d/M kl. HH:mm:ss")}
            </h3>
          </Grid>

          <Grid item sx={{ ml: 4 }}>

            <div>ID kort resultat:

              <div style={{ fontSize: "80%" }}>
                {getFaceDetectionIcon(props.session.automaticFaceCompareResult)}
                {getFaceDetectionIcon(props.session.manualFaceCompareResult)}
              </div>
            </div></Grid>

          <Grid item sx={{ ml: 4 }}>
            {props.session.geoLocation || ""}
          </Grid>
        </Grid>
        <Stack direction="column" sx={{ mb: 1 }}>
          {props.session.screenshot && (
            <>
              <Stack>
                <img src={imageSrc} style={{ cursor: "pointer" }} onClick={() => setShowFullImage(!showFullImage)} width={showFullImage ? "100%" : "600"}></img>
              </Stack>
            </>
          )}
        </Stack>

        {hasMediaRecordings && <>

          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <IconButton onClick={() => eventBus.dispatch(eventGroup + "play", { currentTime: sliderState })}>
              <Play />
            </IconButton>
            <IconButton onClick={pause}>
              <Pause />
            </IconButton>

            <ToggleButtonGroup value={playbackRate} exclusive onChange={setRate}>
              <ToggleButton value="1" aria-label="left aligned">
                1x
              </ToggleButton>
              <ToggleButton value="2" aria-label="centered">
                2x
              </ToggleButton>
              <ToggleButton value="4" aria-label="right aligned">
                4x
              </ToggleButton>
              <ToggleButton value="8" aria-label="justified">
                8x
              </ToggleButton>
            </ToggleButtonGroup>

            <Slider aria-label="Volume" value={sliderState} max={maxSliderValue} onChange={onSliderChange} />

            <AddBookmarkButtonAndDialog session={props.session} progress={sliderState} onAdded={reloadBookmarks} onOpen={pause} />

            <ToggleButtonGroup value={playbackZoom} exclusive onChange={setZoom}>
              <ToggleButton value="32%" aria-label="33%">
                33%
              </ToggleButton>
              <ToggleButton value="48%" aria-label="50%">
                50%
              </ToggleButton>
              <ToggleButton value="100%" aria-label="100%">
                100%
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          <Box>
            {bookmarks === null ? (
              <CircularProgress />
            ) : (
              <>
                <BookmarkList
                  bookmarks={bookmarks}
                  onGoToTimestamp={(value) => {
                    eventBus.dispatch(eventGroup + "setPosition", { currentTime: value });
                    setSliderState(value);

                    setTimeout(() => {
                      eventBus.dispatch(eventGroup + "play", { currentTime: value });
                    }, 500);
                  }}
                />
              </>
            )}
          </Box>

          {props.session.screenCaptures?.map((x, index) => (
            <Media offsetTimeInMilliseconds={x.offsetInMilliseconds || 0}
              key={x.id}
              isVideo={true}
              isScreenshots={false}
              id={x.id || -1}
              eventGroup={eventGroup}
              onDurationLoaded={onDurationLoaded}
              onTimeChanged={(x) => (index === 0 ? onTimeChanged(x) : null)} // === 0: - vi følger kun onTimeChanged event fra den første
            />
          ))}
          {props.session.webcams?.map((x) => (
            <Media key={x.id} offsetTimeInMilliseconds={x.offsetInMilliseconds || 0} isScreenshots={false} isVideo={true} id={x.id || -1} eventGroup={eventGroup} onDurationLoaded={onDurationLoaded} />
          ))}

          <div>
            {props.session.audioRecordings?.map((x) => (
              <Media key={x.id} offsetTimeInMilliseconds={x.offsetInMilliseconds || 0} isScreenshots={false} isVideo={false} id={x.id || -1} eventGroup={eventGroup} onDurationLoaded={onDurationLoaded} />
            ))}
          </div>

        </>}

        {hasScreenshots && <ScreenshotSlider session={props.session} autoPlay={false} playbackZoom={playbackZoom} />}
      </Paper>
    </>
  );
}

const eventBus = {
  on(event: string, callback: (e: any) => void) {
    document.addEventListener(event, (e) => callback((e as CustomEvent).detail));
  },
  dispatch(event: string, data: any) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: string, callback: (e: any) => void) {
    document.removeEventListener(event, callback);
  },
};

function Media(props: {
  id: number;
  isVideo: boolean;
  isScreenshots: boolean;
  eventGroup: string;
  offsetTimeInMilliseconds: number;
  onDurationLoaded: (durationInSeconds: number) => void;
  onTimeChanged?: (currentTimeInSeconds: number) => void;
}) {
  const inputEl = useRef<HTMLVideoElement>(null);

  const onTimeChanged = props.onTimeChanged;

  const [zoom, setZoom] = useState("48%");

  const globalTimeToLocalTime = (globalTime: number) => {
    return globalTime - (props.offsetTimeInMilliseconds / 1000);
  }

  const localTimeTolLobalTime = (localTime: number) => {
    return localTime + (props.offsetTimeInMilliseconds / 1000);
  }

  useEffect(() => {
    if (inputEl.current && onTimeChanged) {
      inputEl.current.ontimeupdate = (a: any) => {
        if (inputEl.current?.currentTime) {
          onTimeChanged(localTimeTolLobalTime(Math.floor(inputEl.current?.currentTime)));
        }
      };
    }
  }, [onTimeChanged]);

  const onDurationLoaded = props.onDurationLoaded;

  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.onloadedmetadata = (a: any) => {
        if (a.target?.duration) {
          onDurationLoaded(a.target.duration);
        }
      };
    }
  }, [onDurationLoaded]);

  useEffect(() => {
    eventBus.on(props.eventGroup + "play", setPosition);

    return () => eventBus.remove(props.eventGroup + "play", setPosition);
  }, []);

  useEffect(() => {
    const callback = () => {
      inputEl.current?.pause();
    };

    eventBus.on(props.eventGroup + "pause", callback);

    return () => eventBus.remove(props.eventGroup + "pause", callback);
  }, []);

  useEffect(() => {
    const callback = () => {
      if (inputEl.current) inputEl.current.playbackRate = 1;
    };

    eventBus.on(props.eventGroup + "playbackRate-1", callback);

    return () => eventBus.remove(props.eventGroup + "playbackRate-1", callback);
  }, []);

  useEffect(() => {
    const callback = () => {
      if (inputEl.current) inputEl.current.playbackRate = 2;
    };

    eventBus.on(props.eventGroup + "playbackRate-2", callback);

    return () => eventBus.remove(props.eventGroup + "playbackRate-2", callback);
  }, []);

  useEffect(() => {
    const callback = () => {
      if (inputEl.current) inputEl.current.playbackRate = 4;
    };

    eventBus.on(props.eventGroup + "playbackRate-4", callback);

    return () => eventBus.remove(props.eventGroup + "playbackRate-4", callback);
  }, []);

  useEffect(() => {
    const callback = () => {
      if (inputEl.current) inputEl.current.playbackRate = 8;
    };

    eventBus.on(props.eventGroup + "playbackRate-8", callback);

    return () => eventBus.remove(props.eventGroup + "playbackRate-8", callback);
  }, []);

  const setPosition = (e: { currentTime: number }) => {
    setCurrentPosition(e.currentTime);
    if (inputEl.current) {
      let newTime = globalTimeToLocalTime(e.currentTime);



      console.log(newTime);
      if (newTime >= -2) {

        if (newTime < 0) newTime = 0;

        inputEl.current.currentTime = newTime;
        inputEl.current.play();
      }
    }
  };

  useEffect(() => {
    eventBus.on(props.eventGroup + "setPosition", setPosition);

    setCurrentPosition(currentPosition);

    return () => eventBus.remove("setPosition", setPosition);
  }, []);

  let [currentPosition, setCurrentPosition] = useState(0);

  useEffect(() => {
    const callback = (d: any) => {
      console.log("Data: " + d);
      setZoom(d);
    };

    eventBus.on(props.eventGroup + "zoom", callback);

    return () => eventBus.remove(props.eventGroup + "zoom", callback);
  }, []);


  return (
    <>

      {props.isVideo && !props.isScreenshots && (
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            resize: "both",
            overflow: "auto",
            backgroundColor: "black",
            padding: "10px",
            margin: "10px",
            width: zoom,
          }}
        >
          <video ref={inputEl} src={getUrl(`/recording/stream/video/${props.id}`)} autoPlay={false} controls width={"100%"} style={{ resize: "both" }}></video>
        </div>
      )}



      {!props.isVideo && !props.isScreenshots && <audio ref={inputEl} src={getUrl(`/recording/stream/audio/${props.id}`)} autoPlay={false} controls></audio>}
    </>
  );
}

function ExamineeChat(props: { recordingUserId: number }) {
  const [loading, setLoading] = useState(true);
  const [fullChat, setFullChat] = useState<FullChatViewModel | null>(null);

  let showInitialChat = new URLSearchParams(useLocation().search).get("showInitialChat");

  const [showChat, setShowChat] = useState(showInitialChat ? true : false);

  const refresh = async () => {
    if (props.recordingUserId) {
      var c = await ApiClient.getFullChatFromRecordingUser(props.recordingUserId);
      console.log("setFullChat");
      setFullChat(c);

      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();

    var hubConnection = getChatHubConnection();

    const methodName = "chat-recordinguser-" + props.recordingUserId;

    console.log(methodName);
    hubConnection.on(methodName, () => {
      console.log("Received chat message");

      refresh();
    });

    hubConnection.start();

    return () => {
      hubConnection.stop();
    };
  }, [props.recordingUserId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!fullChat) {
    return <>Ingen chatbeskeder</>;
  }

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          <Button variant="contained" onClick={() => setShowChat(true)}>
            Vis chat ({fullChat?.messages.length || 0} beskeder)
          </Button>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Box display="flex" justifyContent="flex-end">
            <ChatStatusFields chat={fullChat} />
          </Box>
        </Grid>
      </Grid>

      <React.Fragment>
        <Drawer
          anchor="right"
          open={showChat}
          onClose={() => setShowChat(false)}
          PaperProps={{
            sx: { width: "50%" },
          }}
        >
          {fullChat && <Chat chat={fullChat} />}
        </Drawer>
      </React.Fragment>
    </>
  );
}
